<template>
  <div id="main">
    <div class="about">
      <div class="banner"></div>
      <div class="companyMain">
        <div class="companytitle"></div>
        <Description
          :descData="descData"
          descHeight="360"
          descWidth="370"
        ></Description>
      </div>
      <div class="contact">
        <div class="contactMain">
          <div class="contacttitle"></div>
          <div class="contactContent">
            <div class="content">
              <img src="../../assets/main/about/address.png" alt="" />
              <div>公司地址</div>
              <div>深圳市龙岗区园山街道大康社区沙荷路13号1#办公楼205</div>
            </div>
            <div class="content">
              <img src="../../assets/main/about/phone.png" alt="" />
              <div>联系电话</div>
              <div>139 - 0803 - 5545</div>
            </div>
            <div class="content">
              <img src="../../assets/main/about/email.png" alt="" />
              <div>电子邮箱</div>
              <div>liwei@goldmatrix.cn</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Description from "@/components/desc";
export default {
  components: { Description },
  data() {
    return {
      descData: {
        imgUrl: require("@/assets/main/about/Company Image.webp"),
        title: "金箱科技",
        descOne:
          "金箱科技是一家专注产业数字化创新的方案商和服务提供商，在供应链协同和数字化交易方面有领先的技术能力和服务能力。",
        descTwo:
          "公司围绕“产业协同、数字创新”，面向集团产业群、产业生态，提供数字协同及交易平台，提供技术服务！",
      },
    };
  },
  mounted() {
    const isAsk = this.$route.query.ask;
    const app = document.querySelector("#app");
    if (isAsk == "true") {
      app.scrollTop = 500;
    }
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.about {
  width: 100vw;
  height: 1437px;
  .banner {
    height: 400px;
    background: url("../../assets/main/about/aboutBG.png") no-repeat
      center/cover;
  }
  .contact {
    width: 100vw;
    height: 382px;
    background: rgba(245, 245, 245, 1);
    margin-bottom: 40px;
    .contactMain {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .contacttitle {
        width: 205px;
        height: 41px;
        background: url("../../assets/main/about/Contact-tittle.png") no-repeat;
        margin: 40px auto;
      }
      .contactContent {
        width: 1200px;
        height: 221px;

        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        .content {
          width: 270px;
          height: 100%;

          div:nth-child(2) {
            width: 96px;
            height: 24px;
            color: rgba(51, 51, 51, 1);
            font-size: 24px;
            margin: 0 87px;
          }
          div:nth-child(3) {
            width: 218px;
            height: 36px;
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            margin: 0 auto;
            margin: 17px 26px 20px 26px;
            text-align: center;
          }
          img {
            width: 64px;
            height: 64px;
            margin: 30px 103px 30px 103px;
          }
        }
      }
    }
  }
  .companyMain {
    width: 1200px;
    height: 615px;
    margin: 0 auto;
    overflow: hidden;
    .companytitle {
      width: 243px;
      height: 43px;
      background: url("../../assets/main/about/Company-tittle.png") no-repeat;
      margin: 40px auto;
    }
    .companydesc {
      width: 1200px;
      height: 452px;
      img {
        width: 760px;
        height: 100%;
        float: left;
      }
      .desc {
        width: 440px;
        height: 100%;
        background: rgba(226, 228, 234, 1);
        float: left;
        position: relative;
        .descMain {
          width: 364px;
          height: 372px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          div:nth-child(1) {
            width: 96px;
            height: 24px;
            font-size: 24px;
            line-height: 24px;
            color: rgba(234, 144, 82, 1);
            font-weight: bold;
          }
          div:nth-child(2) {
            width: 368px;
            height: 100px;
            font-size: 16px;
            line-height: 26px;
            font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
            font-weight: normal;
            color: #333333;
            line-height: 26px;
            margin-top: 20px;
          }
          div:nth-child(3) {
            width: 360px;
            height: 1px;
            border-top: 1px solid rgba(102, 102, 102, 1);
            margin: 20px 0;
          }
          div:nth-child(4) {
            width: 364px;
            height: 194px;
            font-size: 14px;
            font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
            font-weight: normal;
            color: #666666;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
